import { isDefinedEntry } from '@kontent-ai/utils';
import { captureException } from '@sentry/react';
import { AjaxOptions, CustomHeaders, ICreateAjax, ProgressCallback, RequestType } from './ajax.ts';
import { QueryStringParams, buildUrlQueryString } from './buildUrlQueryString.ts';
import { toDTO } from './transformToDTO.ts';

export type IRequestContext = {
  readonly appInstanceId?: string;
  readonly authToken?: AuthToken;
  readonly masterEnvironmentId?: Uuid;
  readonly projectId?: Uuid;
  readonly projectContainerId?: Uuid;
};

function getOptions(
  customHeaders: CustomHeaders,
  abortSignal?: AbortSignal,
  requestContext?: IRequestContext,
  requestOptions?: RequestOptions,
): AjaxOptions {
  return {
    customHeaders: getHeaders(customHeaders, requestContext, requestOptions),
    abortSignal,
  };
}

function getHeaders(
  customHeaders: CustomHeaders,
  requestContext?: IRequestContext,
  requestOptions?: RequestOptions,
): CustomHeaders {
  const headerEntries = [
    ...Object.entries(customHeaders),
    ['Authorization', requestContext?.authToken ? `Bearer ${requestContext.authToken}` : undefined],
    ['ClientVersion', self._clientConfig.clientVersion || undefined],
    ['X-AppInstanceId', requestContext?.appInstanceId || undefined],
  ] satisfies ReadonlyArray<Readonly<[keyof CustomHeaders, string | undefined]>>;

  const validHeaderEntries = headerEntries
    .filter(isDefinedEntry)
    .filter(([headerName]) => !requestOptions?.omitHeaders?.includes(headerName));

  return Object.fromEntries(validHeaderEntries);
}

type RequestOptions = {
  readonly omitHeaders: ReadonlyArray<keyof CustomHeaders>;
};

export function createRestProvider(ajax: ICreateAjax) {
  function makeRequest(
    httpVerb: RequestType,
    url: string,
    data: any,
    abortSignal?: AbortSignal,
    requestContext?: IRequestContext,
    requestOptions?: RequestOptions,
  ): Promise<XMLHttpRequest> {
    const options = getOptions(
      { 'Content-type': 'application/json' },
      abortSignal,
      requestContext,
      requestOptions,
    );

    const requestBody = data ? prepareDataForSend(data) : null;
    return ajax.request(httpVerb, url, requestBody, options);
  }

  function makeFileRequest(
    httpVerb: RequestType,
    url: string,
    data: any,
    abortSignal?: AbortSignal,
    requestContext?: IRequestContext,
    requestOptions?: RequestOptions,
  ): Promise<XMLHttpRequest> {
    const options = getOptions(
      { 'Content-type': 'application/octet-stream' },
      abortSignal,
      requestContext,
      requestOptions,
    );

    const requestBody = data ? prepareDataForSend(data) : null;
    return ajax.requestFile(httpVerb, url, requestBody, options);
  }

  return {
    get(
      url: string,
      queryParameters?: QueryStringParams | null,
      abortSignal?: AbortSignal,
      requestContext?: IRequestContext,
      requestOptions?: RequestOptions,
    ): Promise<any> {
      const urlWithQueryParams =
        url + (queryParameters ? buildUrlQueryString(queryParameters) : '');

      // KCL-13884 temporary logging
      const shouldLogFail =
        url.endsWith('4192440f-b944-0135-c768-e05d36ed3e93/type') ||
        url.endsWith('78c26ad4-34c1-02b9-209a-db3bbb6f499d/type');

      return makeRequest(
        'GET',
        urlWithQueryParams,
        null,
        abortSignal,
        requestContext,
        requestOptions,
      )
        .then(verifyStatusCode([200]))
        .then(shouldLogFail ? parseResponseAndLogFail : parseResponse);
    },

    getFile(
      url: string,
      queryParameters: QueryStringParams | null,
      abortSignal?: AbortSignal,
      requestContext?: IRequestContext,
      requestOptions?: RequestOptions,
    ): Promise<any> {
      const urlWithQueryParams =
        url + (queryParameters ? buildUrlQueryString(queryParameters) : '');

      return makeFileRequest(
        'GET',
        urlWithQueryParams,
        null,
        abortSignal,
        requestContext,
        requestOptions,
      ).then((response) => response.response);
    },

    post(
      url: string,
      data: any,
      abortSignal?: AbortSignal,
      requestContext?: IRequestContext,
      requestOptions?: RequestOptions,
    ): Promise<any> {
      return makeRequest('POST', url, data, abortSignal, requestContext, requestOptions)
        .then(verifyStatusCode([200, 201, 202, 204]))
        .then(parseResponse);
    },

    put(
      url: string,
      data: any,
      abortSignal?: AbortSignal,
      requestContext?: IRequestContext,
      requestOptions?: RequestOptions,
    ): Promise<any> {
      return makeRequest('PUT', url, data, abortSignal, requestContext, requestOptions)
        .then(verifyStatusCode([200, 201]))
        .then(parseResponse);
    },

    patch(
      url: string,
      data: any,
      abortSignal?: AbortSignal,
      requestContext?: IRequestContext,
      requestOptions?: RequestOptions,
    ): Promise<any> {
      return makeRequest('PATCH', url, data, abortSignal, requestContext, requestOptions)
        .then(verifyStatusCode([200]))
        .then(parseResponse);
    },

    delete(
      url: string,
      abortSignal?: AbortSignal,
      requestContext?: IRequestContext,
      requestOptions?: RequestOptions,
    ): Promise<any> {
      return makeRequest('DELETE', url, null, abortSignal, requestContext, requestOptions)
        .then(verifyStatusCode([200, 202, 204]))
        .then(parseResponse);
    },

    upload(
      url: string,
      file: any,
      metadata: any,
      uploadProgressCallback: ProgressCallback,
      abortSignal?: AbortSignal,
      requestContext?: IRequestContext,
      requestOptions?: RequestOptions,
    ) {
      const options = getOptions({}, abortSignal, requestContext, requestOptions);

      const formData = new FormData();
      formData.append(file.name, file);
      if (metadata) {
        formData.append('metadata', JSON.stringify(metadata));
      }

      return ajax
        .upload(url, formData, uploadProgressCallback, options)
        .then(verifyStatusCode([201]))
        .then(parseResponse);
    },
  };
}

function prepareDataForSend(data: any): any {
  if (data && typeof data === 'object') {
    const dto = toDTO(data);
    return JSON.stringify(dto);
  }
  if (typeof data === 'string') {
    return JSON.stringify(data);
  }

  return data;
}

function verifyStatusCode(validStatusCodes: number[]) {
  return (response: XMLHttpRequest) => {
    if (validStatusCodes.includes(response.status)) {
      return response;
    }
    // eslint-disable-next-line @typescript-eslint/only-throw-error
    throw response;
  };
}

function parseResponse(response: XMLHttpRequest) {
  // it might be empty string which can't be parsed by JSON.parse
  if (!response.responseText) {
    return null;
  }

  return JSON.parse(response.responseText);
}

// KCL-13884 temporary logging
function parseResponseAndLogFail(response: XMLHttpRequest) {
  // it might be empty string which can't be parsed by JSON.parse
  if (!response.responseText) {
    return null;
  }

  try {
    return JSON.parse(response.responseText);
  } catch (e) {
    captureException(e, (scope) => {
      scope.setTransactionName('KCL-13884 Unterminated string in JSON – new logging');
      scope.setExtras({
        readyState: response.readyState,
        responseHeaders: response.getAllResponseHeaders(),
        responseType: response.responseType,
        responseText: response.responseText,
        responseTextSize: response.responseText.length,
        status: response.status,
      });
      return scope;
    });
    throw e;
  }
}
